export enum NodesEnum {
    COMPANY = 'Empresa',
    UNIT = 'Unidade',
    PLANT = 'Planta',
    AREA = 'Área',
    SUBAREA = 'Subárea',
    EQUIPMENT = 'Equipamento',
    ASSETCLASS = 'Classe do ativo',
    ASSET = 'Ativo',
    LOGICALASSET = 'Ativo'
}